import { PERSON } from "@/components/InmateDetails/Personal";

export const DOMAIN = "dev-e578z005mx6gvs5k.us.auth0.com";
export const CLIENT_ID = "1E6cqNqtSDPL5QvAwk1TXgVIBPbT2dbR";

export const yesno = [
  {
    label: "Yes",
    value: "Y",
  },
  {
    label: "No",
    value: "N",
  },
];

export const clientParams1 = [
  {
    paramContext: "bio:lName",
    paramName: "required",
    paramValue: true,
  },
  {
    paramContext: "bio:fName",
    paramName: "required",
    paramValue: true,
  },
  {
    paramContext: "bio:race",
    paramName: "required",
    paramValue: true,
  },
  {
    paramContext: "bio:sex",
    paramName: "required",
    paramValue: true,
  },
  {
    paramContext: "bio:dob",
    paramName: "required",
    paramValue: true,
  },
  {
    paramContext: "bio:height",
    paramName: "RegularExpression",
    paramValue:
      "(100|[1-8][0-9]{2}|900)||The value for height must be a number between 100 to 900.",
  },
  {
    paramContext: "bio:weight",
    paramName: "RegularExpression",
    paramValue:
      "(50|[5-9][0-9]|[1-4][0-9]{2}|500)||The value for weight must be a number between 50 to 500.",
  },
  // {
  //   paramContext: "bio:pinNo",
  //   paramName: "RegularExpression",
  //   paramValue:
  //     "[0-9]{7}||The value for old CRH must be a number and 7 digit length.",
  // },
  {
    paramContext: "ArrestReports:agency",
    paramName: "required",
    paramValue: true,
  },
];

const clientParams = [
  {
    clientParamID: 1,
    guak: "4444-91",
    paramContext: "GunPermits:PIC_Approved_U",
    paramName: "OverrideSelect",
    paramValue: "YesNo",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 2,
    guak: "4444-92",
    paramContext: "GunPermits:Response1_U",
    paramName: "OverrideSelect",
    paramValue: "YesNo",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 3,
    guak: "4444-93",
    paramContext: "GunPermits:Response2_U",
    paramName: "OverrideSelect",
    paramValue: "YesNo",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 4,
    guak: "4444-94",
    paramContext: "CardBackground",
    paramName: "GunPermits",
    paramValue: "background='..\\images\\GPCardBG.jpg'",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 5,
    guak: "4444-95",
    paramContext: "FormBackground",
    paramName: "GunPermits",
    paramValue: "background='..\\images\\GPFormBG.jpg'",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 6,
    guak: "4444-96",
    paramContext: "SheriffSignature",
    paramName: "GunPermits",
    paramValue: "False",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 7,
    guak: "4444-97",
    paramContext: "nTop",
    paramName: "GunPermits",
    paramValue: ".05",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 8,
    guak: "4444-98",
    paramContext: "nBottom",
    paramName: "GunPermits",
    paramValue: ".05",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 9,
    guak: "4444-99",
    paramContext: "nLeft",
    paramName: "GunPermits",
    paramValue: ".10",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 10,
    guak: "4444-100",
    paramContext: "nRight",
    paramName: "GunPermits",
    paramValue: ".05",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 11,
    guak: "4444-101",
    paramContext: "GunPermits",
    paramName: "FindConflicts",
    paramValue: "GunPermits;;LicenseNumber::License Number::2",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 12,
    guak: "4444-102",
    paramContext: "GunPermits:Police_Department_U",
    paramName: "OverrideSelect",
    paramValue: "PoliceDept",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 13,
    guak: "4444-103",
    paramContext: "AlphaNumericCodes",
    paramName: "IncidentReports",
    paramValue: "True",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 14,
    guak: "4444-104",
    paramContext: "AutomaticSearch",
    paramName: "Jackets",
    paramValue: "True",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 15,
    guak: "4444-105",
    paramContext: "Citations",
    paramName: "FindConflicts",
    paramValue:
      "Citations;;CitationNumber::Citation Number::1|CRN::Case Number::1",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 16,
    guak: "4444-106",
    paramContext: "IncidentReports",
    paramName: "FindConflicts",
    paramValue: "IncidentReports;;CRN_No::CRN::1",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 17,
    guak: "4444-107",
    paramContext: "CivilPapers",
    paramName: "FindConflicts",
    paramValue: "CivilPapers;;CRN::Case Number::1",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 18,
    guak: "4444-108",
    paramContext: "IncidentTypeSelect",
    paramName: "IncidentReports",
    paramValue: "True",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 19,
    guak: "4444-109",
    paramContext: "GetCodeFromCharge",
    paramName: "IncidentReports",
    paramValue: "True",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 20,
    guak: "4444-110",
    paramContext: "IncidentReports:IncidentType1",
    paramName: "OverrideSelect",
    paramValue: "IncidentType",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 21,
    guak: "4444-111",
    paramContext: "IncidentReports:IncidentType2",
    paramName: "OverrideSelect",
    paramValue: "IncidentType",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 22,
    guak: "4444-112",
    paramContext: "IncidentReports:IncidentType3",
    paramName: "OverrideSelect",
    paramValue: "IncidentType",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 23,
    guak: "4444-113",
    paramContext: "ArsonPrefix",
    paramName: "IncidentReports",
    paramValue: "09",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 24,
    guak: "4444-114",
    paramContext: "ICRPerson:OffenderCharge1",
    paramName: "OverrideSelect",
    paramValue: "IncidentType",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 25,
    guak: "4444-115",
    paramContext: "ICRPerson:OffenderCharge2",
    paramName: "OverrideSelect",
    paramValue: "IncidentType",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 26,
    guak: "4444-116",
    paramContext: "ICRPerson:OffenderCharge3",
    paramName: "OverrideSelect",
    paramValue: "IncidentType",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 27,
    guak: "4444-117",
    paramContext: "GetArsonInfoFromCharge",
    paramName: "IncidentReports",
    paramValue: "True",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 28,
    guak: "4444-118",
    paramContext: "IncidentReports:AssaultWeapon",
    paramName: "OverrideSelect",
    paramValue: "WeaponType",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 29,
    guak: "4444-119",
    paramContext: "CivilPapers:AgencyState",
    paramName: "OverrideSelect",
    paramValue: "USState",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 30,
    guak: "4444-120",
    paramContext: "Bio:DLState",
    paramName: "OverrideSelect",
    paramValue: "USState",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 31,
    guak: "4444-121",
    paramContext: "Charge:Disposition",
    paramName: "OverrideSelect",
    paramValue: "OffenderDisposition",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 32,
    guak: "4444-122",
    paramContext: "Charge:BondState",
    paramName: "OverrideSelect",
    paramValue: "USState",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 33,
    guak: "4444-123",
    paramContext: "Client",
    paramName: "UCR",
    paramValue: "MN",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 34,
    guak: "4444-124",
    paramContext: "RequireNumericUCR",
    paramName: "ArrestReports",
    paramValue: "False",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 35,
    guak: "4444-125",
    paramContext: "FacialRecognition",
    paramName: "QuickSearch",
    paramValue: "False",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 36,
    guak: "4444-126",
    paramContext: "LicenseeNumberBase",
    paramName: "GunPermits",
    paramValue: "1000000",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 37,
    guak: "4444-127",
    paramContext: "FreeFormType",
    paramName: "CivilPapers",
    paramValue: "False",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 38,
    guak: "4444-128",
    paramContext: "ArrestReports:Charges1",
    paramName: "OverrideSelect",
    paramValue: "Charges",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 39,
    guak: "4444-129",
    paramContext: "ArrestReports:Charges2",
    paramName: "OverrideSelect",
    paramValue: "Charges",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 40,
    guak: "4444-130",
    paramContext: "Warrant:Charge",
    paramName: "OverrideSelect",
    paramValue: "Charges",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 41,
    guak: "4444-131",
    paramContext: "PersonToServeCitySelect",
    paramName: "CivilPapers",
    paramValue: "False",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 42,
    guak: "4444-132",
    paramContext: "Charge:ClearanceReason",
    paramName: "OverrideSelect",
    paramValue: "ChargeClearance",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 43,
    guak: "4444-133",
    paramContext: "LicenseNumberLength",
    paramName: "GunPermits",
    paramValue: "7",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 44,
    guak: "4444-134",
    paramContext: "LicenseNumberPrefix",
    paramName: "GunPermits",
    paramValue: "45-",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 45,
    guak: "4444-135",
    paramContext: "CourtName",
    paramName: "Remittance",
    paramValue: "City of ???",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 46,
    guak: "4444-136",
    paramContext: "AccountNumber",
    paramName: "Remittance",
    paramValue: "???",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 47,
    guak: "4444-137",
    paramContext: "County",
    paramName: "Remittance",
    paramValue: "??? County",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 48,
    guak: "4444-138",
    paramContext: "InterviewDetails:LocationState",
    paramName: "OverrideSelect",
    paramValue: "USState",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 49,
    guak: "4444-139",
    paramContext: "InterviewDetails:Place_Of_Birth_U",
    paramName: "OverrideSelect",
    paramValue: "USState",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 50,
    guak: "4444-140",
    paramContext: "InterviewDetails:TagState",
    paramName: "OverrideSelect",
    paramValue: "USState",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 51,
    guak: "4444-141",
    paramContext: "InterviewDetails:Zone_U",
    paramName: "OverrideSelect",
    paramValue: "WeaponType",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 52,
    guak: "4444-142",
    paramContext: "Jurisdiction",
    paramName: "Globals",
    paramValue: "Anoka County",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 53,
    guak: "4444-143",
    paramContext: "Enabled",
    paramName: "Barcodes",
    paramValue: "False",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 54,
    guak: "4444-144",
    paramContext: "Font",
    paramName: "Barcodes",
    paramValue: "3 of 9 Barcode",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 55,
    guak: "4444-145",
    paramContext: "Bio:Suffix",
    paramName: "OverrideSelect",
    paramValue: "Suffix",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 56,
    guak: "4444-146",
    paramContext: "Bio:State",
    paramName: "OverrideSelect",
    paramValue: "USState",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 57,
    guak: "4444-147",
    paramContext: "Bio:MailingState",
    paramName: "OverrideSelect",
    paramValue: "USState",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 58,
    guak: "4444-148",
    paramContext: "Bio:Place_Of_Birth_State",
    paramName: "OverrideSelect",
    paramValue: "USState",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 59,
    guak: "4444-149",
    paramContext: "Enabled",
    paramName: "NewArchitecture",
    paramValue: "True",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 60,
    guak: "4444-150",
    paramContext: "Vehicles:Vehicle_Registration_State",
    paramName: "OverrideSelect",
    paramValue: "USState",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 61,
    guak: "4444-151",
    paramContext: "PropertyBoat:Boat_Registration_State",
    paramName: "OverrideSelect",
    paramValue: "USState",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 62,
    guak: "4444-152",
    paramContext: "NIBRSArrestees:Arrestee_Armed_With_1",
    paramName: "OverrideSelect",
    paramValue: "Armed_With",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 63,
    guak: "4444-153",
    paramContext: "NIBRSArrestees:Arrestee_Armed_With_2",
    paramName: "OverrideSelect",
    paramValue: "Armed_With",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 64,
    guak: "4444-154",
    paramContext: "NIBRSArrestees:Arrested_Near_Scene",
    paramName: "OverrideSelect",
    paramValue: "Unk",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 65,
    guak: "4444-155",
    paramContext: "NIBRSOffenses:Offense_Type",
    paramName: "OverrideSelect",
    paramValue: "NIBRSIncidentTypes",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 66,
    guak: "4444-156",
    paramContext: "NIBRSOffenses:Completed",
    paramName: "OverrideSelect",
    paramValue: "YesNo",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 67,
    guak: "4444-157",
    paramContext: "NIBRSOffenses:Method_Of_Entry",
    paramName: "OverrideSelect",
    paramValue: "MO",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 68,
    guak: "4444-158",
    paramContext: "NIBRSPeople:Suspected_of_Using_1",
    paramName: "OverrideSelect",
    paramValue: "Suspected_of_Using",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 69,
    guak: "4444-159",
    paramContext: "NIBRSPeople:Suspected_of_Using_3",
    paramName: "OverrideSelect",
    paramValue: "Suspected_of_Using",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 70,
    guak: "4444-160",
    paramContext: "NIBRSOffenses:Type_Of_Criminal_Activity_1",
    paramName: "OverrideSelect",
    paramValue: "CriminalActivity",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 71,
    guak: "4444-161",
    paramContext: "NIBRSOffenses:Type_Of_Criminal_Activity_2",
    paramName: "OverrideSelect",
    paramValue: "CriminalActivity",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 72,
    guak: "4444-162",
    paramContext: "NIBRSOffenses:Type_Of_Criminal_Activity_3",
    paramName: "OverrideSelect",
    paramValue: "CriminalActivity",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 73,
    guak: "4444-163",
    paramContext: "NIBRSOffenses:Weapon_Type_1",
    paramName: "OverrideSelect",
    paramValue: "Weapon_Type",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 74,
    guak: "4444-164",
    paramContext: "NIBRSOffenses:Weapon_Type_2",
    paramName: "OverrideSelect",
    paramValue: "Weapon_Type",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 324,
    guak: "4444-560921",
    paramContext: "DisplayPinNo",
    paramName: "ProfileReport",
    paramValue: "True",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 75,
    guak: "4444-165",
    paramContext: "NIBRSOffenses:Weapon_Type_3",
    paramName: "OverrideSelect",
    paramValue: "Weapon_Type",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 76,
    guak: "4444-166",
    paramContext: "NIBRSArrestees:Disposition",
    paramName: "OverrideSelect",
    paramValue: "OffenderDisposition",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 77,
    guak: "4444-167",
    paramContext: "NIBRSPeople:Suspected_of_Using_2",
    paramName: "OverrideSelect",
    paramValue: "Suspected_of_Using",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 78,
    guak: "4444-168",
    paramContext: "NIBRSPeople:Drug_Type_1",
    paramName: "OverrideSelect",
    paramValue: "Drug_Type",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 79,
    guak: "4444-169",
    paramContext: "NIBRSPeople:Drug_Type_2",
    paramName: "OverrideSelect",
    paramValue: "Drug_Type",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 80,
    guak: "4444-170",
    paramContext: "NIBRSPeople:Drug_Type_3",
    paramName: "OverrideSelect",
    paramValue: "Drug_Type",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 81,
    guak: "4444-171",
    paramContext: "PropertyDrug:Suspected_Drug_Type",
    paramName: "OverrideSelect",
    paramValue: "Drug_Type",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 82,
    guak: "4444-172",
    paramContext: "NIBRSPeople:Leoka_Assignment",
    paramName: "OverrideSelect",
    paramValue: "AssaultAssignment",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 83,
    guak: "4444-173",
    paramContext: "NIBRSPeople:Person_Type",
    paramName: "OverrideSelect",
    paramValue: "PersonType",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 84,
    guak: "4444-174",
    paramContext: "NIBRSPeople:Aggravated_Assault_Circumstances_1",
    paramName: "OverrideSelect",
    paramValue: "Aggravated_Assault",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 85,
    guak: "4444-175",
    paramContext: "NIBRSPeople:Aggravated_Assault_Circumstances_2",
    paramName: "OverrideSelect",
    paramValue: "Aggravated_Assault",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 86,
    guak: "4444-176",
    paramContext: "NIBRSPeople:Additional_Justifiable_Homicide",
    paramName: "OverrideSelect",
    paramValue: "Additional_Homicide",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 87,
    guak: "4444-177",
    paramContext: "Bio:Skin",
    paramName: "OverrideSelect",
    paramValue: "Skin",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 88,
    guak: "4444-178",
    paramContext: "Bio:Build",
    paramName: "OverrideSelect",
    paramValue: "Build",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 89,
    guak: "4444-179",
    paramContext: "Bio:Teeth",
    paramName: "OverrideSelect",
    paramValue: "Teeth",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 90,
    guak: "4444-180",
    paramContext: "NIBRSArrestees: Disposition",
    paramName: "OverrideSelect",
    paramValue: "NIBRSJuvenileDisposition",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 91,
    guak: "4444-181",
    paramContext: "TaxiPermitChargesExtra:Result_of_Appeal_U",
    paramName: "OverrideSelect",
    paramValue: "TaxiPermitDisposition",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 92,
    guak: "4444-182",
    paramContext: "ApprovalCheckBoxes",
    paramName: "TaxiPermits",
    paramValue:
      "Ambassador_Classes_Complete_U:Cleared_GCIC_U:Fingerprint_Taken_U:Driver_History_Checked_U:Doctor_Certificate_U",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 93,
    guak: "4444-183",
    paramContext: "ExpirationDateAlgorithm",
    paramName: "TaxiPermits",
    paramValue: "NextBirthday(theForm.DOB.value)",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 94,
    guak: "4444-184",
    paramContext: "ApplicationFee",
    paramName: "TaxiPermits",
    paramValue: "30",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 95,
    guak: "4444-185",
    paramContext: "RenewalFee",
    paramName: "TaxiPermits",
    paramValue: "20",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 96,
    guak: "4444-186",
    paramContext: "LateFee",
    paramName: "TaxiPermits",
    paramValue: "10",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 97,
    guak: "4444-187",
    paramContext: "CanRenewWithinXDays",
    paramName: "TaxiPermits",
    paramValue: "45",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 98,
    guak: "4444-188",
    paramContext: "PeriodForPermit",
    paramName: "TaxiPermits",
    paramValue: "1",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 99,
    guak: "4444-189",
    paramContext: "OfficerReports:ReportType",
    paramName: "OverrideSelect",
    paramValue: "ReportType",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 100,
    guak: "4444-190",
    paramContext: "AssignItemNumberAutomatically",
    paramName: "EvidenceProperty",
    paramValue: "False",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 101,
    guak: "4444-191",
    paramContext: "State",
    paramName: "NIBRS",
    paramValue: "MN",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 102,
    guak: "4444-192",
    paramContext: "BusinessesExtra:Foreign_Corp_Registration_State_U",
    paramName: "OverrideSelect",
    paramValue: "USState",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 103,
    guak: "4444-193",
    paramContext: "LicenseMain:Adult_Clubs_U",
    paramName: "OverrideSelect",
    paramValue: "AdultClubs",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 104,
    guak: "4444-194",
    paramContext: "LicenseMain:License_Class_U",
    paramName: "OverrideSelect",
    paramValue: "LicenseClass",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 105,
    guak: "4444-195",
    paramContext: "LicenseMain:Assistant_U",
    paramName: "OverrideSelect",
    paramValue: "YesNo",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 106,
    guak: "4444-196",
    paramContext: "CreateSystemTableLayouts",
    paramName: "Globals",
    paramValue: "False",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 107,
    guak: "4444-197",
    paramContext: "NIBRSIncidentReportsExtra:Lighting_U",
    paramName: "OverrideSelect",
    paramValue: "Lighting",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 108,
    guak: "4444-198",
    paramContext: "NIBRSIncidentReportsExtra:Weather_U",
    paramName: "OverrideSelect",
    paramValue: "Weather",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 109,
    guak: "4444-199",
    paramContext: "NIBRSOffensesExtra:Point_Of_Entry_U",
    paramName: "OverrideSelect",
    paramValue: "PointOfEntry",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 110,
    guak: "4444-200",
    paramContext: "DisplayDistinguishingCharacteristics",
    paramName: "Bio",
    paramValue: "True",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 340,
    guak: "4444-734732",
    paramContext: "IncidentReports:ORI",
    paramName: "OverrideSelect",
    paramValue: "ORI",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 341,
    guak: "4444-734733",
    paramContext: "IncidentReportOffenses:IncidentOffenseID",
    paramName: "Typeahead",
    paramValue: "True",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 113,
    guak: "4444-203",
    paramContext: "NIBRSOffensesExtra:Rape_Exam_U",
    paramName: "OverrideSelect",
    paramValue: "YesNo",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 114,
    guak: "4444-204",
    paramContext: "SectorType",
    paramName: "NIBRS",
    paramValue: "Northport",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 411,
    guak: "4444-1452616",
    paramContext: "AssociatesTableView",
    paramName: "ProfileReport",
    paramValue: "ProfileReportAssociates",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 116,
    guak: "4444-206",
    paramContext: "NIBRSPeopleExtra:Suspect_Armed_U",
    paramName: "OverrideSelect",
    paramValue: "YesNoUnk",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 117,
    guak: "4444-207",
    paramContext: "Mandatory",
    paramName: "AccidentPeople",
    paramValue: "",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 118,
    guak: "4444-208",
    paramContext: "Mandatory",
    paramName: "Bankruptcy",
    paramValue:
      "Case_Number:Chapter:Status:Date_Filed:Time_Filed:Date_Received:Time_Received",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 119,
    guak: "4444-209",
    paramContext: "PersonToServeBioInterface",
    paramName: "CivilPapers",
    paramValue: "Simple1",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 120,
    guak: "4444-210",
    paramContext: "Mandatory",
    paramName: "CivilPapersNew",
    paramValue: "Civil_Paper_Type:CRN:Date_Received",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 121,
    guak: "4444-211",
    paramContext: "AssignEvidenceRecordNumberAutomatically",
    paramName: "EvidenceProperty",
    paramValue: "False",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 122,
    guak: "4444-212",
    paramContext: "Mandatory",
    paramName: "WeaponConfiscationWeapons",
    paramValue:
      "Date_Confiscated:Time_Confiscated:Confiscating_Officer:Confiscating_Officer_Badge:Special_Handling:Property_Type:Quality",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 123,
    guak: "4444-213",
    paramContext: "Mandatory",
    paramName: "EvidenceProperty",
    paramValue: "Case_Number:Case_Status",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 124,
    guak: "4444-214",
    paramContext: "Mandatory",
    paramName: "EvidencePropertyItems",
    paramValue: "Special_Handling:Property_Type",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 125,
    guak: "4444-215",
    paramContext: "EnableBatches",
    paramName: "NIBRS",
    paramValue: "False",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 126,
    guak: "4444-216",
    paramContext: "AgencyCounty",
    paramName: "Globals",
    paramValue: "Anoka",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 342,
    guak: "4444-734734",
    paramContext: "IncidentReportPeopleExtra:Charge_Code_U",
    paramName: "OverrideSelect",
    paramValue: "UCRPersonChargeCode",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 128,
    guak: "4444-218",
    paramContext: "NIBRSOffensesExtra:Rape_Treatment_U",
    paramName: "OverrideSelect",
    paramValue: "YesNo",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 130,
    guak: "4444-220",
    paramContext: "NIBRSPeopleExtra:Suspect_Armed_U_With",
    paramName: "OverrideSelect",
    paramValue: "Weapon_Type",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 131,
    guak: "4444-221",
    paramContext: "NumImages",
    paramName: "Lineups",
    paramValue: "6",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 132,
    guak: "4444-222",
    paramContext: "BookingsNew",
    paramName: "FindConflicts",
    paramValue:
      "InventoryItems;;Storage_Location_2_ID__QA::Loc. 1::2*Storage_Location_3_ID__QA::Loc. 2::2",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 133,
    guak: "4444-223",
    paramContext: "InmatePropertyItems",
    paramName: "FindConflicts",
    paramValue:
      "InventoryItems;;Storage_Location_2_ID__QA::Loc. 1::2*Storage_Location_3_ID__QA::Loc. 2::2",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 134,
    guak: "4444-224",
    paramContext: "BookingsNew_TransportReport",
    paramName: "ThirdSignatureLine",
    paramValue: "False",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 135,
    guak: "4444-225",
    paramContext: "Mandatory",
    paramName: "Attorney",
    paramValue: "LName",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 136,
    guak: "4444-226",
    paramContext: "ShowIndigent",
    paramName: "BookingsNew_ArrestReports",
    paramValue: "False",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 343,
    guak: "4444-734735",
    paramContext: "IncidentReportPeopleExtra:General_Offense_Character_U",
    paramName: "OverrideSelect",
    paramValue: "UCROffenseChar",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 137,
    guak: "4444-227",
    paramContext: "PropertyHasQuantity",
    paramName: "NIBRS",
    paramValue:
      "Bicycles:Clothes/Furs:Computer Hardware/Software:Consumable Goods:Farm Equipment:Household Goods:Merchandise:Office-type Equipment:Purses/Handbags/Wallets:Radios/TVs/VCRs:Recordings-Audio/Visual:Tools:Vehicle Parts/Accessories",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 138,
    guak: "4444-228",
    paramContext: "CRNPrefix",
    paramName: "CaseManagement",
    paramValue: "CRN",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 139,
    guak: "4444-229",
    paramContext: "Select",
    paramName: "StaffRank",
    paramValue: "True",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 140,
    guak: "4444-230",
    paramContext: "BookingsNew",
    paramName: "EnableInmateCashAccounting",
    paramValue: "False",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 141,
    guak: "4444-231",
    paramContext: "Bookings",
    paramName: "HenryFingerprintInformationEnabled",
    paramValue: "False",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 142,
    guak: "4444-232",
    paramContext: "Bookings",
    paramName: "SwansonExportEnabled",
    paramValue: "False",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 143,
    guak: "4444-233",
    paramContext: "Bookings",
    paramName: "SwansonExportFilePath",
    paramValue: "C:\\SwansonExport",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 144,
    guak: "4444-234",
    paramContext: "NIBRS",
    paramName: "SectorType",
    paramValue: "",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 145,
    guak: "4444-235",
    paramContext: "RefLists:RefListGlobalID",
    paramName: "ManualAutonumber",
    paramValue: "True",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 146,
    guak: "4444-236",
    paramContext: "ModuleParams36To37",
    paramName: "Upgrade",
    paramValue: "1",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 413,
    guak: "4444-1460741",
    paramContext: "SheriffAddress4",
    paramName: "CivilPapersNew",
    paramValue: "763.323.5010",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 331,
    guak: "4444-586885",
    paramContext: "MAX_AUDIT_LOG_ENTRIES_TO_RETURN",
    paramName: "AuditLog",
    paramValue: "2000",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 332,
    guak: "4444-645813",
    paramContext: "CustomDictionaryPath",
    paramName: "SpellCheck",
    paramValue: "C:\\PCI Customer Websites\\Anokal\\",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 333,
    guak: "4444-687810",
    paramContext: "BioExtra:US_Citizen_U",
    paramName: "OverrideSelect",
    paramValue: "YesNo",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 334,
    guak: "4444-733316",
    paramContext: "AgeAtEntryDate1",
    paramName: "Bookings",
    paramValue: "ArrestReports.Arrest_Date",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 322,
    guak: "4444-500095",
    paramContext: "ClassificationDateQuestion3",
    paramName: "Bookings",
    paramValue: "Classification Revalidation Date",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 323,
    guak: "4444-500096",
    paramContext: "LinkedDocumentApplications",
    paramName: "Globals",
    paramValue:
      "*.doc; *.txt; *.jpg; *.pdf; *.htm; *.html; *.xls; *.xlsx; *.docx",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 345,
    guak: "4444-734739",
    paramContext: "Submission",
    paramName: "UCR",
    paramValue: "On",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 346,
    guak: "4444-734740",
    paramContext: "StuffInitialInformationIntoBio",
    paramName: "Jackets",
    paramValue: "False",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 348,
    guak: "4444-734742",
    paramContext: "RequireVerificationOfJacketFields",
    paramName: "InmateMovementRules",
    paramValue: "False",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 349,
    guak: "4444-734743",
    paramContext: "RequireVerificationOfJacketFields",
    paramName: "JacketsJackets",
    paramValue: "False",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 350,
    guak: "4444-734744",
    paramContext: "RequireVerificationOfJacketFields",
    paramName: "ServiceAttempts",
    paramValue: "False",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 351,
    guak: "4444-734745",
    paramContext: "RequireVerificationOfJacketFields",
    paramName: "Staff",
    paramValue: "False",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 352,
    guak: "4444-734746",
    paramContext: "RequireVerificationOfJacketFields",
    paramName: "VictimNotification",
    paramValue: "False",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 272,
    guak: "ANOKA-117-467021",
    paramContext: "BannerControl",
    paramName: "Bookings",
    paramValue: "Inmate_Status_ID",
    staffGroupsID: null,
    staffID: 6,
  },
  {
    clientParamID: 353,
    guak: "4444-734747",
    paramContext: "RequireVerificationOfJacketFields",
    paramName: "VisitorLog",
    paramValue: "False",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 354,
    guak: "4444-734748",
    paramContext: "PropertyLineItemsTableView",
    paramName: "ProfileReport",
    paramValue: "AnokaInmatePropertyItemsVOne",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 355,
    guak: "4444-827164",
    paramContext: "LivescanOld",
    paramName: "Bookings",
    paramValue: "True",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 147,
    guak: "4444-238",
    paramContext: "AgencyPhone",
    paramName: "Globals",
    paramValue: "Your Agency Phone Number",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 148,
    guak: "4444-253",
    paramContext: "IsDebugMode",
    paramName: "Globals",
    paramValue: "False",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 149,
    guak: "4444-254",
    paramContext: "Bankruptcy",
    paramName: "FindConflicts",
    paramValue: "Bankruptcy;;Case_Number::Case Number::1",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 150,
    guak: "4444-255",
    paramContext: "Bookings:Controlling_Agency_ORI_ID",
    paramName: "Typeahead",
    paramValue: "True",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 151,
    guak: "4444-256",
    paramContext: "Charges:Controlling_Agency_ORI_ID",
    paramName: "Typeahead",
    paramValue: "True",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 152,
    guak: "4444-257",
    paramContext: "Charges:Charge_Description_ID",
    paramName: "Typeahead",
    paramValue: "True",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 153,
    guak: "4444-258",
    paramContext: "Detentions:Controlling_Agency_ORI_ID",
    paramName: "Typeahead",
    paramValue: "True",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 154,
    guak: "4444-259",
    paramContext: "DetentionsCharges:Charge_Description_ID",
    paramName: "Typeahead",
    paramValue: "True",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 155,
    guak: "4444-260",
    paramContext: "Warrants:Charge",
    paramName: "Typeahead",
    paramValue: "True",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 156,
    guak: "4444-261",
    paramContext: "Warrants:Original_Charge",
    paramName: "Typeahead",
    paramValue: "True",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 415,
    guak: "4444-1500457",
    paramContext: "TrainingDatabase",
    paramName: "Globals",
    paramValue: "False",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 158,
    guak: "4444-263",
    paramContext: "Mandatory",
    paramName: "NIBRSIncidentReports",
    paramValue: "AgencyID:ORI",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 159,
    guak: "4444-265",
    paramContext: "QuickAddCharges",
    paramName: "Bookings",
    paramValue: "False",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 160,
    guak: "4444-266",
    paramContext: "RebookEnabled",
    paramName: "Bookings",
    paramValue: "True",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 161,
    guak: "4444-267",
    paramContext: "NeverRequireTransportingOfficerOnMoves",
    paramName: "Bookings",
    paramValue: "True",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 162,
    guak: "4444-268",
    paramContext: "QuickSearchFocus",
    paramName: "Bookings",
    paramValue: "ndLNameX",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 163,
    guak: "4444-269",
    paramContext: "RequireBed",
    paramName: "Bookings",
    paramValue: "False",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 164,
    guak: "4444-270",
    paramContext: "RequireCell",
    paramName: "Bookings",
    paramValue: "False",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 165,
    guak: "4444-271",
    paramContext: "LivescanExport",
    paramName: "Bookings",
    paramValue: "True",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 166,
    guak: "4444-272",
    paramContext: "LivescanFormat",
    paramName: "Bookings",
    paramValue: "Anoka",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 167,
    guak: "4444-273",
    paramContext: "LivescanExportFolder",
    paramName: "Bookings",
    paramValue: "f:\\Inetpub\\ftproot\\PCI\\LocalUser\\",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 168,
    guak: "4444-274",
    paramContext: "AspectRatio",
    paramName: "Lineups",
    paramValue: "4:5",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 169,
    guak: "4444-275",
    paramContext: "ArrestReportsExtra:Needs_Classification_U",
    paramName: "OverrideSelect",
    paramValue: "YesNoUnknown",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 170,
    guak: "4444-276",
    paramContext: "ArrestReportsExtra:Risk_U",
    paramName: "OverrideSelect",
    paramValue: "Prisoner_Risk",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 171,
    guak: "4444-277",
    paramContext: "DisplayEmergencyContactInFullInterface",
    paramName: "Bio",
    paramValue: "False",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 172,
    guak: "4444-278",
    paramContext: "WarnOnNew",
    paramName: "Bookings",
    paramValue:
      "You are about to add a new inmate to the count.  Do you want to continue?",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 173,
    guak: "4444-279",
    paramContext: "Organization:Agency",
    paramName: "OverrideSelect",
    paramValue: "Housing_Agency",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 274,
    guak: "4444-31501",
    paramContext: "OrderBy",
    paramName: "WarrantRoots",
    paramValue: "Date_Added:Time_Added",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 356,
    guak: "4444-827165",
    paramContext: "LivescanXML",
    paramName: "Bookings",
    paramValue: "True",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 357,
    guak: "4444-827166",
    paramContext: "LivescanExportFolderXML",
    paramName: "Bookings",
    paramValue: "f:\\Inetpub\\ftproot\\pci\\localuser\\",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 358,
    guak: "4444-827167",
    paramContext: "LivescanExportSubFoldersXML",
    paramName: "Bookings",
    paramValue: "TenPXML1",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 359,
    guak: "4444-902025",
    paramContext: "HoldingAgency",
    paramName: "Livescan",
    paramValue: "MN0020100",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 360,
    guak: "4444-911951",
    paramContext: "localAgencyORI",
    paramName: "Livescan",
    paramValue: "MN002013C",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 361,
    guak: "4444-966302",
    paramContext: "FieldSourceToolTip",
    paramName: "Globals",
    paramValue: "True",
    staffGroupsID: null,
    staffID: 17647,
  },
  {
    clientParamID: 416,
    guak: "4444-1500571",
    paramContext: "Expected_Back_Time",
    paramName: "InmateTracking",
    paramValue: "1600",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 174,
    guak: "4444-280",
    paramContext: "ArrestReports:Arrest_City",
    paramName: "OverrideSelect",
    paramValue: "Arrest_City",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 175,
    guak: "4444-281",
    paramContext: "Charges:Offense_City",
    paramName: "OverrideSelect",
    paramValue: "Arrest_City",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 176,
    guak: "4444-282",
    paramContext: "ArrestReportsExtra:Towing_Company_U",
    paramName: "OverrideSelect",
    paramValue: "Towing_Company",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 177,
    guak: "4444-283",
    paramContext: "BioExtra:County_U",
    paramName: "OverrideSelect",
    paramValue: "Sentencing_County",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 178,
    guak: "4444-284",
    paramContext: "WarrantsExtra:Service_Type_U",
    paramName: "OverrideSelect",
    paramValue: "Anoka_Warrant_Service_Type",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 179,
    guak: "4444-285",
    paramContext: "WarrantsExtra:Grid_U",
    paramName: "OverrideSelect",
    paramValue: "PatrolZones",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 180,
    guak: "4444-286",
    paramContext: "BioExtra:Country_Code_U",
    paramName: "OverrideSelect",
    paramValue: "Anoka_S3_Country_Code",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 181,
    guak: "4444-287",
    paramContext: "BioExtra:Marital_Status_U",
    paramName: "OverrideSelect",
    paramValue: "Marital_Status",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 182,
    guak: "4444-288",
    paramContext: "BioExtra:Citizen_Code_U",
    paramName: "OverrideSelect",
    paramValue: "YesNoUnknown",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 183,
    guak: "4444-289",
    paramContext: "IncludeBioExtra",
    paramName: "CivilPapers",
    paramValue: "False",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 184,
    guak: "4444-290",
    paramContext: "IncludeBioExtra",
    paramName: "Warrants",
    paramValue: "False",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 185,
    guak: "4444-291",
    paramContext: "ArrestReports:Housed_For",
    paramName: "OverrideSelect",
    paramValue: "AnokaCommitmentAgency",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 186,
    guak: "4444-292",
    paramContext: "Organization:Shift",
    paramName: "OverrideSelect",
    paramValue: "AnokaShift",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 187,
    guak: "4444-293",
    paramContext: "OfficerReports:Shift",
    paramName: "OverrideSelect",
    paramValue: "AnokaShift",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 188,
    guak: "4444-294",
    paramContext: "BioExtra:A_Number_U",
    paramName: "RegularExpression",
    paramValue:
      'A\\d{7,9}||The A-Number must be in the format "A" followed by 7, 8 or 9 digits.  Do not use dashes.',
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 189,
    guak: "4444-295",
    paramContext: "Staff:Password",
    paramName: "RegularExpression",
    paramValue:
      "^(?=.*\\d)(?=.*[a-zA-Z])(?=.*[!$#%]).{8,}$||Password must be at least 8 chars, with at least one letter, one number, and one special char from !$#%",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 190,
    guak: "4444-296",
    paramContext: "SanctionsOnOffenses",
    paramName: "OfficerReports",
    paramValue: "True",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 191,
    guak: "4444-297",
    paramContext: "SanctionsOnReport",
    paramName: "OfficerReports",
    paramValue: "False",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 192,
    guak: "4444-298",
    paramContext: "ShowBookingIDAtTop",
    paramName: "Bookings",
    paramValue: "True",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 193,
    guak: "4444-299",
    paramContext: "QuickAddBioAliases",
    paramName: "WarrantRoots",
    paramValue: "True",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 194,
    guak: "4444-300",
    paramContext: "QuickAddBioAliases",
    paramName: "Bookings",
    paramValue: "True",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 195,
    guak: "4444-301",
    paramContext: "QuickAddDistinguishingCharacteristics",
    paramName: "WarrantRoots",
    paramValue: "True",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 196,
    guak: "4444-302",
    paramContext: "QuickAddDistinguishingCharacteristics",
    paramName: "Bookings",
    paramValue: "True",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 197,
    guak: "4444-303",
    paramContext: "Addresses:AddressCity",
    paramName: "RegularExpression",
    paramValue:
      "[A-Za-z0-9 ]{1,21}||The city name must be 21 characters or less and be alpha-numeric (spaces are also allowed).",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 198,
    guak: "4444-304",
    paramContext: "Bio:FBI",
    paramName: "RegularExpression",
    paramValue:
      "[A-Za-z0-9]{1,9}||The FBI number must be 9 alphanumeric characters or less.",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 199,
    guak: "4444-305",
    paramContext: "Addresses:AddressStreetNumber",
    paramName: "RegularExpression",
    paramValue:
      "[A-Za-z0-9 ]{1,9}||The street number (house number) must be 9 characters or less and be alpha-numeric (spaces are also allowed).",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 200,
    guak: "4444-306",
    paramContext: "Addresses:AddressStreet1",
    paramName: "RegularExpression",
    paramValue:
      "[A-Za-z0-9/ \\-]*||The street name field allows spaces, alphanumeric characters, dashes, and forward-slashes (/) only.",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 201,
    guak: "4444-307",
    paramContext: "Addresses:AddressZip",
    paramName: "RegularExpression",
    paramValue:
      "[0-9\\-]{1,10}||The zip code field allows numbers and dashes only and must be 10 characters or less.",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 202,
    guak: "4444-308",
    paramContext: "WarrantStateInfo:Miscellaneous_Field",
    paramName: "RegularExpression",
    paramValue:
      '[A-Za-z0-9 \\\\!"#$%&\'()*+,\\-/:;<=>?@[\\].]{1,500}||The MIS field allows space, alphanumeric, !, ", #, $, %, &, apostrophe, (, ), *, +, comma, dash, /,  colon, semi-colon, <, =, >, ?, @, [, \\, ], and periods.',
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 203,
    guak: "4444-309",
    paramContext: "WarrantStateInfo:Miscellaneous_Field_Auto",
    paramName: "RegularExpression",
    paramValue:
      '[A-Za-z0-9 \\\\!"#$%&\'()*+,\\-/:;<=>?@[\\].]{1,500}||The MIS field allows space, alphanumeric, !, ", #, $, %, &, apostrophe, (, ), *, +, comma, dash, /,  colon, semi-colon, <, =, >, ?, @, [, \\, ], and periods.',
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 204,
    guak: "4444-310",
    paramContext: "Bio:FName",
    paramName: "RegularExpression",
    paramValue:
      "[A-Za-z0-9 \\-,']*||Name fields allows spaces, alphanumeric characters, commas, dashes and apostrophes only.",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 205,
    guak: "4444-311",
    paramContext: "Bio:LName",
    paramName: "RegularExpression",
    paramValue:
      "[A-Za-z0-9 \\-,']*||Name fields allows spaces, alphanumeric characters, commas, dashes and apostrophes only.",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 206,
    guak: "4444-312",
    paramContext: "Bio:MName",
    paramName: "RegularExpression",
    paramValue:
      "[A-Za-z0-9 \\-,']*||Name fields allows spaces, alphanumeric characters, commas, dashes and apostrophes only.",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 207,
    guak: "4444-313",
    paramContext: "Warrants:CRN_No",
    paramName: "RegularExpression",
    paramValue:
      "[A-Za-z0-9 \\-]{1,20}||The case number (OCA) field allows spaces, alphanumeric characters and dashes only.",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 208,
    guak: "4444-314",
    paramContext: "Bio:DLNumber",
    paramName: "RegularExpression",
    paramValue:
      "[A-Za-z0-9]{1,20}||The driver's license number field allows alphanumeric characters only and must be 20 characters or less.",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 365,
    guak: "4444-1084622",
    paramContext: "Addresses:TownshipOfResidence",
    paramName: "OverrideSelect",
    paramValue: "TownshipOfResidence",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 366,
    guak: "4444-1084623",
    paramContext: "Addresses:County/City/TownshipOfResidence",
    paramName: "ResidenceFields",
    paramValue: "False",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 367,
    guak: "4444-1084627",
    paramContext: "Block__QA",
    paramName: "OverrideSelect",
    paramValue: "Block",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 368,
    guak: "4444-1084628",
    paramContext: "Cell__QA",
    paramName: "OverrideSelect",
    paramValue: "Cell",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 369,
    guak: "4444-1084629",
    paramContext: "Bed__QA",
    paramName: "OverrideSelect",
    paramValue: "Bed",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 209,
    guak: "4444-315",
    paramContext: "Bio:SID",
    paramName: "RegularExpression",
    paramValue:
      "[A-Za-z0-9]{1,10}||The State ID (SID) field allows alphanumeric characters only and must be 10 characters or less.",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 210,
    guak: "4444-316",
    paramContext: "Warrants:WarrantNumber",
    paramName: "RegularExpression",
    paramValue:
      "[A-Za-z0-9]{1,15}||The Warrant Number field allows alphanumeric characters only and must be 15 characters or less.",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 211,
    guak: "4444-317",
    paramContext: "Charges:Case_Number",
    paramName: "RegularExpression",
    paramValue:
      "^[A-Za-z]{2}[0-9]{2}-[0-9]{6}$|[0-9]{8}||The Case Number field must be either 'aa##-######' (11 chars) or '########' (8 chars).",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 212,
    guak: "4444-318",
    paramContext: "Warrants:Additional_Charge_Info",
    paramName: "RegularExpression",
    paramValue:
      "[A-Za-z0-9 ]{1,30}||The Additional Charge Info field allows alphanumeric characters and dashes only and must be 30 characters or less.",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 213,
    guak: "4444-319",
    paramContext: "QuickSearchDefaultToInCustody",
    paramName: "Bookings",
    paramValue: "False",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 214,
    guak: "4444-320",
    paramContext: "InmateBilling:Housed_For",
    paramName: "OverrideSelect",
    paramValue: "AnokaCommitmentAgency",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 215,
    guak: "4444-321",
    paramContext: "BioExtra:Dominant_Hand_U",
    paramName: "OverrideSelect",
    paramValue: "Dominant_Hand",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 216,
    guak: "4444-322",
    paramContext: "BioExtra:Speech_U",
    paramName: "OverrideSelect",
    paramValue: "Speech",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 217,
    guak: "4444-323",
    paramContext: "DisplayBuildSkinTeeth",
    paramName: "Bio",
    paramValue: "True",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 218,
    guak: "4444-324",
    paramContext: "BioExtra:General_Appearance_U",
    paramName: "OverrideSelect",
    paramValue: "General_Appearance",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 219,
    guak: "4444-325",
    paramContext: "FreeFormType",
    paramName: "OfficerReports",
    paramValue: "False",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 220,
    guak: "4444-326",
    paramContext: "FreeFormAction",
    paramName: "OfficerReports",
    paramValue: "False",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 221,
    guak: "4444-327",
    paramContext: "ArrestReports:Hold_Reason_ID",
    paramName: "OverrideSelect",
    paramValue: "Inmate_Hold_Reason",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 222,
    guak: "4444-328",
    paramContext: "ArrestReportsExtra:S3_Hold_Reason_U",
    paramName: "OverrideSelect",
    paramValue: "Inmate_Hold_Reason",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 223,
    guak: "4444-329",
    paramContext: "ArrestReportsExtra:S3_Detention_Type_U",
    paramName: "OverrideSelect",
    paramValue: "Detention_Type",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 224,
    guak: "4444-330",
    paramContext: "ArrestReportsExtra:S3_Hold_Location_U",
    paramName: "OverrideSelect",
    paramValue: "Hold_Location_Cd",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 225,
    guak: "4444-331",
    paramContext: "ArrestReportsExtra:S3_LE_Agency_U",
    paramName: "OverrideSelect",
    paramValue: "Release_to_Authority",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 226,
    guak: "4444-332",
    paramContext: "BookingsTableView",
    paramName: "JacketSummary",
    paramValue: "AnokaJacketSummaryBookings",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 227,
    guak: "4444-333",
    paramContext: "ProcessedName",
    paramName: "Warrants",
    paramValue: "CLEARED",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 228,
    guak: "4444-334",
    paramContext: "UseSupplementalShowFlag",
    paramName: "Interface",
    paramValue: "False",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 229,
    guak: "4444-335",
    paramContext: "GraphSupport",
    paramName: "TableViews",
    paramValue: "True",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 230,
    guak: "4444-336",
    paramContext: "CivilPapersNew:CRN",
    paramName: "ManualAutonumber",
    paramValue: "True",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 231,
    guak: "4444-337",
    paramContext: "CivilPapersPeopleExtra:Business_U",
    paramName: "OverrideSelect",
    paramValue: "YesNo",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 232,
    guak: "4444-338",
    paramContext: "StaffExtra:Medical_Staff_Type_U",
    paramName: "OverrideSelect",
    paramValue: "Medical_Staff_Type",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 233,
    guak: "4444-339",
    paramContext: "Mandatory",
    paramName: "Staff",
    paramValue: "Agency",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 234,
    guak: "4444-340",
    paramContext: "Mandatory",
    paramName: "Charges",
    paramValue: "Controlling_Agency:Charge_Level_ID",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 297,
    guak: "4444-335964",
    paramContext: "VineExportEnabled",
    paramName: "Bookings",
    paramValue: "False",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 236,
    guak: "4444-342",
    paramContext: "MultipleAliases",
    paramName: "Bio",
    paramValue: "True",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 237,
    guak: "4444-343",
    paramContext: "CivilPapersNewExtra:Levy_Type_U",
    paramName: "OverrideSelect",
    paramValue: "Levy_Type",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 238,
    guak: "4444-344",
    paramContext: "IncludeStatus",
    paramName: "CivilPapers",
    paramValue: "True",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 239,
    guak: "4444-345",
    paramContext: "MultiAgency",
    paramName: "Globals",
    paramValue: "True",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 240,
    guak: "4444-346",
    paramContext: "ManualBookingNumber",
    paramName: "Bookings",
    paramValue: "True",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 241,
    guak: "4444-347",
    paramContext: "Bookings:Booking_Number",
    paramName: "ManualAutonumber",
    paramValue: "True",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 242,
    guak: "4444-348",
    paramContext: "GetInfoFromPrevious",
    paramName: "Warrants",
    paramValue: "False",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 243,
    guak: "4444-349",
    paramContext: "BannerControl",
    paramName: "Bookings",
    paramValue: "Classification_ID",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 298,
    guak: "4444-335965",
    paramContext: "VineExportFolder",
    paramName: "Bookings",
    paramValue: "F:\\VINE\\",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 245,
    guak: "4444-351",
    paramContext: "MedicalQuestionnaire",
    paramName: "Bookings",
    paramValue: "Anoka Medical Intake",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 246,
    guak: "4444-352",
    paramContext: "AgeEventHandler",
    paramName: "Bookings",
    paramValue: "SetInmateTypeBasedOnAge",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 247,
    guak: "4444-353",
    paramContext: "DefaultSentencingCounty",
    paramName: "Charges",
    paramValue: "154700092",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 248,
    guak: "4444-354",
    paramContext: "SetFocusToFirstOnModify",
    paramName: "Globals",
    paramValue: "False",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 249,
    guak: "4444-355",
    paramContext: "DLExpiresOnBirthday",
    paramName: "Bio",
    paramValue: "True",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 250,
    guak: "4444-357",
    paramContext: "DistinguishingCharacteristicsTableView",
    paramName: "ProfileReport",
    paramValue: "False",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 417,
    guak: "4444-1500591",
    paramContext: "RosterDays",
    paramName: "WorkCrews",
    paramValue: "7",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 252,
    guak: "4444-359",
    paramContext: "LockedFieldImage",
    paramName: "Globals",
    paramValue: "images/LockedFieldAtlanta.bmp",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 418,
    guak: "4444-1500697",
    paramContext: "ReportHeadingColor",
    paramName: "IncidentReports",
    paramValue: "Black",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 259,
    guak: "4444-32195",
    paramContext: "BannerControlSub",
    paramName: "Bookings",
    paramValue: "Inmate_Status_ID",
    staffGroupsID: 5,
    staffID: null,
  },
  {
    clientParamID: 419,
    guak: "4444-1500698",
    paramContext: "UseAddressFromUnServedPapers",
    paramName: "CivilPapers",
    paramValue: "True",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 261,
    guak: "4444-257683",
    paramContext: "ReportSubHeading",
    paramName: "Globals",
    paramValue: "ANOKA, MN",
    staffGroupsID: 5,
    staffID: null,
  },
  {
    clientParamID: 268,
    guak: "4444-34059",
    paramContext: "FieldSourceToolTip",
    paramName: "Globals",
    paramValue: "True",
    staffGroupsID: null,
    staffID: 3,
  },
  {
    clientParamID: 270,
    guak: "ANOKA-117-467022",
    paramContext: "BannerControlSub",
    paramName: "Bookings",
    paramValue: "Workhouse_Status_ID",
    staffGroupsID: null,
    staffID: 6,
  },
  {
    clientParamID: 271,
    guak: "ANOKA-117-467020",
    paramContext: "DefaultToOnlyInCustodyOrScheduled",
    paramName: "Bookings",
    paramValue: "False",
    staffGroupsID: null,
    staffID: 6,
  },
  {
    clientParamID: 370,
    guak: "4444-1084630",
    paramContext: "EnableLoanDestinationDropdown",
    paramName: "InmateTracking",
    paramValue: "True",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 371,
    guak: "4444-1084631",
    paramContext: "RequireVerificationOfJacketFields",
    paramName: "Transport",
    paramValue: "False",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 372,
    guak: "4444-1084642",
    paramContext: "EnforceKeepSeparateConflicts",
    paramName: "InmateTracking",
    paramValue: "False",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 373,
    guak: "4444-1084643",
    paramContext: "ClassificationTurnOnForBoarding",
    paramName: "Bookings",
    paramValue: "False",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 374,
    guak: "4444-1084644",
    paramContext: "AutomaticallyAcceptBoardingPropertyTransfer",
    paramName: "Bookings",
    paramValue: "True",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 375,
    guak: "4444-1084645",
    paramContext: "EnableKeepSeparateConflicts",
    paramName: "InmateTracking",
    paramValue: "True",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 376,
    guak: "4444-1084646",
    paramContext: "RequireVerificationOfJacketFields",
    paramName: "BookingsJacketsJackets",
    paramValue: "False",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 377,
    guak: "4444-1084647",
    paramContext: "JailIncidents:Incident_Number",
    paramName: "ManualAutonumber",
    paramValue: "True",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 378,
    guak: "4444-1084648",
    paramContext: "MinorViolationColumns",
    paramName: "JailIncidents",
    paramValue: "3",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 420,
    guak: "4444-1500699",
    paramContext: "DisplayBookingOfficerFullName",
    paramName: "ProfileReport",
    paramValue: "False",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 254,
    guak: "ANOKA-117-467027",
    paramContext: "DefaultToOnlyInCustodyOrScheduled",
    paramName: "Bookings",
    paramValue: "False",
    staffGroupsID: 4,
    staffID: null,
  },
  {
    clientParamID: 255,
    guak: "ANOKA-117-467028",
    paramContext: "BannerControl",
    paramName: "Bookings",
    paramValue: "Inmate_Status_ID",
    staffGroupsID: 4,
    staffID: null,
  },
  {
    clientParamID: 256,
    guak: "ANOKA-117-467037",
    paramContext: "BannerControlSub",
    paramName: "Bookings",
    paramValue: "Workhouse_Status_ID",
    staffGroupsID: 4,
    staffID: null,
  },
  {
    clientParamID: 423,
    guak: "4444-1500702",
    paramContext: "ShortenArrestAgency",
    paramName: "Bookings",
    paramValue: "True",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 424,
    guak: "4444-1500703",
    paramContext: "WarrantCharges:Charge",
    paramName: "Typeahead",
    paramValue: "True",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 275,
    guak: "4444-31502",
    paramContext: "OrderBy",
    paramName: "Bookings",
    paramValue: "Date_Added:Time_Added",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 276,
    guak: "4444-31503",
    paramContext: "ArrestingOfficerUseTextBox",
    paramName: "Bookings",
    paramValue: "True",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 277,
    guak: "4444-31504",
    paramContext: "ArrestReports:Agency",
    paramName: "OverrideSelect",
    paramValue: "Controlling_Agency",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 278,
    guak: "4444-31505",
    paramContext: "GetChargeLevelFromCharge",
    paramName: "Bookings",
    paramValue: "True",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 279,
    guak: "4444-33506",
    paramContext: "RequireVerificationOfJacketFields",
    paramName: "Bookings",
    paramValue: "False",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 280,
    guak: "4444-33508",
    paramContext: "ForcePrintFooter",
    paramName: "Globals",
    paramValue: "True",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 281,
    guak: "4444-33509",
    paramContext: "ClassificationQuestion1",
    paramName: "Bookings",
    paramValue: "D.  Recommended Custody Level",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 282,
    guak: "4444-33510",
    paramContext: "ClassificationDateQuestion1",
    paramName: "Bookings",
    paramValue: "Assessment Date",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 283,
    guak: "4444-33511",
    paramContext: "ClassificationQuestion2",
    paramName: "Bookings",
    paramValue: "B.  Final Custody Level",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 284,
    guak: "4444-33512",
    paramContext: "ClassificationDateQuestion2",
    paramName: "Bookings",
    paramValue: "Supervisor Approval Date",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 285,
    guak: "4444-33514",
    paramContext: "OmitAllLogos",
    paramName: "Reports",
    paramValue: "False",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 286,
    guak: "4444-33515",
    paramContext: "BoloHeaderText",
    paramName: "BoloReport",
    paramValue: "Sheriff's Bulletin",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 287,
    guak: "4444-33516",
    paramContext: "DefaultToOnlyInCustody",
    paramName: "Bookings",
    paramValue: "True",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 288,
    guak: "4444-33519",
    paramContext: "LikeMatching",
    paramName: "Jackets",
    paramValue: "True",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 289,
    guak: "4444-33520",
    paramContext: "BatchPropertyTransfer",
    paramName: "Bookings",
    paramValue: "True",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 299,
    guak: "4444-335966",
    paramContext: "VineConfigFile",
    paramName: "Bookings",
    paramValue: "E:\\Program Files\\PoliceCentral\\VINE\\VINEConfig.xml",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 300,
    guak: "4444-335967",
    paramContext: "VineLogPath",
    paramName: "Bookings",
    paramValue: "F:\\VINE\\",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 301,
    guak: "4444-335968",
    paramContext: "VINEFTP",
    paramName: "Bookings",
    paramValue: "False",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 302,
    guak: "4444-380831",
    paramContext: "ReleaseControlFingerprint",
    paramName: "Bookings",
    paramValue: "False",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 303,
    guak: "4444-401059",
    paramContext: "SwitchPersonBusiness",
    paramName: "CivilPapers",
    paramValue: "Anoka",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 304,
    guak: "4444-401060",
    paramContext: "CivilPapersPeopleExtra:Suffix_U",
    paramName: "OverrideSelect",
    paramValue: "Suffix",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 306,
    guak: "4444-401062",
    paramContext: "DisplayCompletionStatus",
    paramName: "CivilPapers",
    paramValue: "False",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 307,
    guak: "4444-402405",
    paramContext: "DisplayAbandoned",
    paramName: "CivilPapers",
    paramValue: "False",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 308,
    guak: "4444-420647",
    paramContext: "QuickSearchFocus",
    paramName: "CivilPapersNew",
    paramValue: "ndLNameX",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 309,
    guak: "4444-431299",
    paramContext: "Bookings",
    paramName: "CameraTwainDriver",
    paramValue: "FlashBus Spectrim TWAIN32",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 310,
    guak: "4444-431300",
    paramContext: "Bookings",
    paramName: "FingerprintTwainDriver",
    paramValue: "CMT USB Scanner Twain Source",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 311,
    guak: "4444-444160",
    paramContext: "DisplayLoginUser",
    paramName: "Bookings",
    paramValue: "True",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 312,
    guak: "4444-452632",
    paramContext: "VisitorLogExtra:Visitor_Relationship_U",
    paramName: "OverrideSelect",
    paramValue: "Emergency_Contact_Relationship",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 425,
    guak: "4444-1500704",
    paramContext: "WarrantCharges:Original_Charge",
    paramName: "Typeahead",
    paramValue: "True",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 426,
    guak: "4444-1500708",
    paramContext: "Bio:CellPhone",
    paramName: "RegularExpression",
    paramValue:
      "^[0-9]{1}[0-9]{2}-[0-9]{3}-[0-9]{4}$||Phone numbers can only contain numbers and dashes must be in the format '###-###-####'.",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 315,
    guak: "4444-500088",
    paramContext: "InmateBillingUseSubTotals",
    paramName: "Bookings",
    paramValue: "True",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 316,
    guak: "4444-500089",
    paramContext: "Type",
    paramName: "Search",
    paramValue: "Starts With",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 363,
    guak: "4444-1084620",
    paramContext: "Addresses:CountyOfResidence",
    paramName: "OverrideSelect",
    paramValue: "CountyOfResidence",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 379,
    guak: "4444-1084649",
    paramContext: "MajorViolationColumns",
    paramName: "JailIncidents",
    paramValue: "3",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 380,
    guak: "4444-1084650",
    paramContext: "MinorViolationColumnWidth",
    paramName: "JailIncidents",
    paramValue: "225",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 381,
    guak: "4444-1084651",
    paramContext: "MajorViolationColumnWidth",
    paramName: "JailIncidents",
    paramValue: "225",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 382,
    guak: "4444-1084652",
    paramContext: "MinorViolationHeading",
    paramName: "JailIncidents",
    paramValue: "Class II Violations",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 383,
    guak: "4444-1084653",
    paramContext: "MajorViolationHeading",
    paramName: "JailIncidents",
    paramValue: "Class III Violations",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 384,
    guak: "4444-1084654",
    paramContext: "DisplayCorrectiveMeasuresAppeal",
    paramName: "JailIncidents",
    paramValue: "False",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 385,
    guak: "4444-1084655",
    paramContext: "AcceptClassIViolation",
    paramName: "JailIncidents",
    paramValue: "True",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 386,
    guak: "4444-1084656",
    paramContext: "AcceptSection",
    paramName: "JailIncidents",
    paramValue: "True",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 387,
    guak: "4444-1084657",
    paramContext: "clientValidation",
    paramName: "JailIncidents",
    paramValue: "ValidateJailIncidentsAnoka",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 388,
    guak: "4444-1084658",
    paramContext: "IncludeHolidaysWeekends",
    paramName: "JailIncidents",
    paramValue: "True",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 389,
    guak: "4444-1084843",
    paramContext: "MentalHealthQuestionnaire",
    paramName: "Bookings",
    paramValue: "Mental Health",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 390,
    guak: "4444-1084844",
    paramContext: "Intake Medical Buttons",
    paramName: "Bookings",
    paramValue:
      "ndMedicalQuestionnaire:Medical Questionnaire&ndMentalHealth:New Mental Health",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 391,
    guak: "4444-1084845",
    paramContext: "TakeOutTPTB",
    paramName: "BookingQueue",
    paramValue: "True",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 392,
    guak: "4444-1084846",
    paramContext: "TakeOutBookingDetails",
    paramName: "BookingQueue",
    paramValue: "True",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 393,
    guak: "4444-1084847",
    paramContext: "QuickAddInmateTracking",
    paramName: "Bookings",
    paramValue: "False",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 394,
    guak: "4444-1084848",
    paramContext: "Bookings:Agency",
    paramName: "Typeahead",
    paramValue: "True",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 395,
    guak: "4444-1084849",
    paramContext: "Bookings:Arrest_City",
    paramName: "Typeahead",
    paramValue: "True",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 396,
    guak: "4444-1084850",
    paramContext: "Charges:Offense_City",
    paramName: "Typeahead",
    paramValue: "True",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 397,
    guak: "4444-1084851",
    paramContext: "Warrants:Offense_City",
    paramName: "Typeahead",
    paramValue: "True",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 398,
    guak: "4444-1084852",
    paramContext: "CourtExtra:Court_Reason_U",
    paramName: "OverrideSelect",
    paramValue: "Court_Disposition",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 399,
    guak: "4444-1084853",
    paramContext: "Mandatory",
    paramName: "Court",
    paramValue: "Court_Reason_U",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 400,
    guak: "4444-1090225",
    paramContext: "AutoFillMiscellaneousField",
    paramName: "Warrants",
    paramValue: "False",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 401,
    guak: "4444-1090226",
    paramContext: "ValidationPage",
    paramName: "Warrants",
    paramValue: "ValidateWarrantAnoka.vbs",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 402,
    guak: "4444-1114416",
    paramContext: "EnforceTransportRestrictions",
    paramName: "InmateMovement",
    paramValue: "True",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 404,
    guak: "4444-1171387",
    paramContext: "SheriffAddress2",
    paramName: "CivilPapersNew",
    paramValue: "13301 Hanson Blvd. NW",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 405,
    guak: "4444-1171388",
    paramContext: "SheriffAddress3",
    paramName: "CivilPapersNew",
    paramValue: "Andover, MN 55304",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 421,
    guak: "4444-1500700",
    paramContext: "DisplayQuestionIdentity",
    paramName: "Bio",
    paramValue: "True",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 263,
    guak: "4444-257264",
    paramContext: "DefaultToOnlyInCustodyOrScheduled",
    paramName: "Bookings",
    paramValue: "False",
    staffGroupsID: 6,
    staffID: null,
  },
  {
    clientParamID: 264,
    guak: "4444-257265",
    paramContext: "BannerControl",
    paramName: "Bookings",
    paramValue: "Inmate_Status_ID",
    staffGroupsID: 6,
    staffID: null,
  },
  {
    clientParamID: 265,
    guak: "4444-257266",
    paramContext: "BannerControlSub",
    paramName: "Bookings",
    paramValue: "Workhouse_Status_ID",
    staffGroupsID: 6,
    staffID: null,
  },
  {
    clientParamID: 266,
    guak: "4444-257805",
    paramContext: "ReportHeading",
    paramName: "Globals",
    paramValue: "Anoka County Jail",
    staffGroupsID: 6,
    staffID: null,
  },
  {
    clientParamID: 267,
    guak: "ANOKA-117-467030",
    paramContext: "DefaultToOnlyMyAgency",
    paramName: "Bookings",
    paramValue: "True",
    staffGroupsID: 13,
    staffID: null,
  },
  {
    clientParamID: 269,
    guak: "4444-34066",
    paramContext: "FieldSourceToolTip",
    paramName: "Globals",
    paramValue: "True",
    staffGroupsID: null,
    staffID: 5,
  },
  {
    clientParamID: 273,
    guak: "ANOKA-117-467147",
    paramContext: "FieldSourceToolTip",
    paramName: "Globals",
    paramValue: "True",
    staffGroupsID: null,
    staffID: 62,
  },
  {
    clientParamID: 296,
    guak: "4444-319716",
    paramContext: "SortBioPhotosOnImageType",
    paramName: "Bookings",
    paramValue: "True",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 412,
    guak: "4444-1452619",
    paramContext: "AutoSetToServed",
    paramName: "CivilPapers",
    paramValue: "True",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 347,
    guak: "4444-734741",
    paramContext: "RequireVerificationOfJacketFields",
    paramName: "AddressBook",
    paramValue: "False",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 364,
    guak: "4444-1084621",
    paramContext: "Addresses:CityOfResidence",
    paramName: "OverrideSelect",
    paramValue: "CityOfResidence",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 403,
    guak: "4444-1136713",
    paramContext: "BatchReturnToOtherIsActive",
    paramName: "BatchMovement",
    paramValue: "True",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 406,
    guak: "4444-1208557",
    paramContext: "DefaultScheduledMoveActualTime",
    paramName: "InmateTracking",
    paramValue: "True",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 407,
    guak: "4444-1208558",
    paramContext: "SearchOnlyFromAgency",
    paramName: "Bookings",
    paramValue: "True",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 408,
    guak: "4444-1209432",
    paramContext: "DisplayRelationship",
    paramName: "Associates",
    paramValue: "True",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 409,
    guak: "4444-1241790",
    paramContext: "IncidentReportPeople:UCROffenderDisposition",
    paramName: "OverrideSelect",
    paramValue: "UCROffenderDisposition",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 410,
    guak: "4444-1241791",
    paramContext: "IncidentReportPeople:UCRJuvenileDisposition",
    paramName: "OverrideSelect",
    paramValue: "UCRJuvenileDisposition",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 414,
    guak: "4444-1463511",
    paramContext: "Sheriff",
    paramName: "CivilPapers",
    paramValue: "James Stuart",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 422,
    guak: "4444-1500701",
    paramContext: "ValidationPage",
    paramName: "Supplementals",
    paramValue: "ValidateSupplementalsAnoka.vbs",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 427,
    guak: "4444-1500709",
    paramContext: "Bio:Daytime_Phone",
    paramName: "RegularExpression",
    paramValue:
      "^[0-9]{1}[0-9]{2}-[0-9]{3}-[0-9]{4}$||Phone numbers can only contain numbers and dashes must be in the format '###-###-####'.",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 428,
    guak: "4444-1500710",
    paramContext: "Bio:Phone",
    paramName: "RegularExpression",
    paramValue:
      "^[0-9]{1}[0-9]{2}-[0-9]{3}-[0-9]{4}$||Phone numbers can only contain numbers and dashes must be in the format '###-###-####'.",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 429,
    guak: "4444-1500730",
    paramContext: "UsePOBNCIC",
    paramName: "Bio",
    paramValue: "True",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 430,
    guak: "4444-1500742",
    paramContext: "Enabled",
    paramName: "WarrantSubmission",
    paramValue: "True",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 431,
    guak: "4444-1500765",
    paramContext: "DefaultReleaseTime",
    paramName: "Bookings",
    paramValue: "04:00",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 432,
    guak: "4444-1500779",
    paramContext: "TestingDatabase",
    paramName: "Globals",
    paramValue: "True",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 433,
    guak: "4444-1500780",
    paramContext: "ReportHeading",
    paramName: "Globals",
    paramValue: "ANOKA COUNTY SHERIFF'S OFFICE",
    staffGroupsID: 5,
    staffID: null,
  },
  {
    clientParamID: 434,
    guak: "4444-1500781",
    paramContext: "ReportHeading",
    paramName: "Globals",
    paramValue: "ANOKA COUNTY COMMUNITY CORRECTIONS",
    staffGroupsID: 4,
    staffID: null,
  },
  {
    clientParamID: 435,
    guak: "4444-1500782",
    paramContext: "ClientLogo",
    paramName: "Globals",
    paramValue: "ClientLogo - ACSO_new_badge.jpg",
    staffGroupsID: 5,
    staffID: null,
  },
  {
    clientParamID: 436,
    guak: "4444-1500783",
    paramContext: "ClientLogo",
    paramName: "Globals",
    paramValue: "ClientLogo - Anoka_Community_Corrections_Patch.jpg",
    staffGroupsID: 4,
    staffID: null,
  },
  {
    clientParamID: 437,
    guak: "4444-1500792",
    paramContext: "Towing_Company_U",
    paramName: "ArrestReportsExtra",
    paramValue: "ArrestInformation",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 438,
    guak: "4444-1500793",
    paramContext: "Intake_War_Check_Date_U",
    paramName: "ArrestReportsExtra",
    paramValue: "ArrestInformation",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 439,
    guak: "4444-1500794",
    paramContext: "Release_War_Check_Date_U",
    paramName: "ArrestReportsExtra",
    paramValue: "ReleaseInformation",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 440,
    guak: "4444-1500795",
    paramContext: "UseStickyFields",
    paramName: "Charges",
    paramValue: "True",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 441,
    guak: "4444-1500828",
    paramContext: "SpecialFees",
    paramName: "CivilPapers",
    paramValue: "CivilPaperFeesAnoka.vbs",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 442,
    guak: "4444-1500829",
    paramContext: "Mandatory",
    paramName: "TrustAccountingNewTransactions",
    paramValue: "Category:Received_By_StaffID",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 443,
    guak: "4444-1500830",
    paramContext: "UseTrustAccounting",
    paramName: "CivilPapers",
    paramValue: "True",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 444,
    guak: "4444-1500833",
    paramContext: "Bookings",
    paramName: "HideInTableView",
    paramValue:
      "User:Submitted_To_MRAP:Description_Cached:SubmittedToLivescan:Initial_QWA_IPAddress LastModified",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 446,
    guak: "4444-1500835",
    paramContext: "Bio",
    paramName: "HideInTableView",
    paramValue: "OTN:Aliases:Age1:Age2:DOBSpecial:Title",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 448,
    guak: "4444-1500842",
    paramContext: "ValidationPage",
    paramName: "CivilPapersPeople",
    paramValue: "ValidateCivilPapersPeopleAnoka.vbs",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 449,
    guak: "4444-1500844",
    paramContext: "SearchIris",
    paramName: "Globals",
    paramValue: "False",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 450,
    guak: "4444-1500846",
    paramContext: "UseDLSpecial",
    paramName: "Bio",
    paramValue: "True",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 290,
    guak: "4444-33521",
    paramContext: "Charges:Original_Charge_Description_ID",
    paramName: "Typeahead",
    paramValue: "True",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 291,
    guak: "4444-33522",
    paramContext: "UseEHM",
    paramName: "Bookings",
    paramValue: "False",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 292,
    guak: "4444-33523",
    paramContext: "CCWExportEnabled",
    paramName: "Bookings",
    paramValue: "True",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 293,
    guak: "4444-33524",
    paramContext: "CCWExportFolder",
    paramName: "Bookings",
    paramValue: "F:\\Inetpub\\ftproot\\PCI\\LocalUser\\FTPUser\\CCWInterface\\",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 294,
    guak: "4444-33525",
    paramContext: "IWSExportFolder",
    paramName: "Bookings",
    paramValue: "f:\\Inetpub\\ftproot\\PCI\\LocalUser\\Iware\\",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 295,
    guak: "4444-33526",
    paramContext: "LivescanExportSubFolders",
    paramName: "Bookings",
    paramValue: "",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 305,
    guak: "4444-401061",
    paramContext: "RequireVerificationOfJacketFields",
    paramName: "CivilPapersPeople",
    paramValue: "False",
    staffGroupsID: null,
    staffID: null,
  },
  {
    clientParamID: 445,
    guak: "4444-1500834",
    paramContext: "ArrestReports",
    paramName: "HideInTableView",
    paramValue:
      "BookingID:Hold_Reason_ID:Inmate_Type_ID:Classification_ID:Release_Reason_ID:Workhouse_Status_ID:Scheduled_Booking_Clearance_Reason_ID:Inmate_Status_ID:Detention_Type_ID",
    staffGroupsID: null,
    staffID: null,
  },
];

clientParams.push(...clientParams1);

export default clientParams;

export const staticFields = {
  personalInfo: {
    [PERSON.INMATE]: [
      {
        columnName: "lName",
        type: "varchar(10)",
        tableName: "Bio",
        label: "Last Name",
        required: true,
      },
      {
        columnName: "fName",
        type: "varchar(10)",
        tableName: "Bio",
        label: "First Name",
        required: true,
      },
      {
        columnName: "mName",
        type: "varchar(10)",
        tableName: "Bio",
        label: "Middle Name",
      },
      {
        columnName: "suffix",
        type: "bit",
        tableName: "Bio",
        label: "Suffix",
        isID: false,
      },
      {
        columnName: "pinNo",
        type: "varchar(30)",
        tableName: "Bio",
        label: "Old CRH#",
      },
      {
        columnName: "enrolleeID",
        type: "varchar(50)",
        tableName: "Bio",
        label: "Enrollee ID",
        disabled: true,
      },
      {
        columnName: "race",
        type: "bit",
        tableName: "Bio",
        label: "Race",
        required: true,
        isID: false,
      },
      {
        columnName: "sex",
        type: "bit",
        tableName: "Bio",
        label: "Gender",
        required: true,
        isID: false,
      },
      {
        columnName: "height",
        type: "int",
        tableName: "Bio",
        label: "Height",
      },
      {
        columnName: "weight",
        type: "int",
        tableName: "Bio",
        label: "Weight",
      },
      {
        columnName: "hair",
        type: "bit",
        tableName: "Bio",
        label: "Hair Color",
        isID: false,
      },
      {
        columnName: "eyes",
        type: "bit",
        tableName: "Bio",
        label: "Eye Color",
        isID: false,
      },
      {
        columnName: "glasses",
        type: "bit",
        tableName: "Bio",
        label: "Glasses",
        isID: false,
      },
      {
        refListName: "hair_Length",
        columnName: "hairLength",
        type: "bit",
        tableName: "Bio",
        label: "Hair Length",
        isID: false,
      },
      {
        refListName: "facial_Hair",
        columnName: "facialHair",
        type: "bit",
        tableName: "Bio",
        label: "Facial Hair",
        isID: false,
      },
      {
        columnName: "complexion",
        type: "bit",
        tableName: "Bio",
        label: "Complexion",
        isID: false,
      },
      {
        columnName: "dlNumber",
        type: "varchar(12)",
        tableName: "Bio",
        label: "Drivers License",
      },
      {
        refListName: "USState",
        columnName: "dlState",
        type: "bit",
        tableName: "Bio",
        label: "DL state",
        isID: false,
      },
      {
        columnName: "dlExpiration",
        type: "date",
        tableName: "Bio",
        label: "Expires",
      },
      {
        columnName: "ssn",
        type: "varchar(18)",
        tableName: "Bio",
        label: "SSN",
      },
      {
        columnName: "dob",
        type: "date",
        tableName: "Bio",
        label: "DOB",
        required: true,
      },
      {
        columnName: "ageAtEntry",
        type: "int",
        tableName: "Bio",
        label: "Age",
        disabled: true,
      },
    ],
    [PERSON.VISITOR]: [
      {
        columnName: "lName",
        type: "varchar(10)",
        tableName: "Bio",
        label: "Last Name",
      },
      {
        columnName: "fName",
        type: "varchar(10)",
        tableName: "Bio",
        label: "First Name",
      },
      {
        columnName: "mName",
        type: "varchar(10)",
        tableName: "Bio",
        label: "Middle Name",
      },
      {
        columnName: "suffix",
        type: "bit",
        tableName: "Bio",
        label: "Suffix",
      },
      {
        columnName: "sex",
        type: "bit",
        tableName: "Bio",
        label: "Gender",
      },
      {
        columnName: "dob",
        type: "date",
        tableName: "Bio",
        label: "DOB",
      },
    ],
    [PERSON.BOOKINGS]: [
      {
        columnName: "lName",
        type: "varchar(10)",
        tableName: "Bio",
        label: "Last Name",
        required: true,
      },
      {
        columnName: "fName",
        type: "varchar(10)",
        tableName: "Bio",
        label: "First Name",
        required: true,
      },
      {
        columnName: "mName",
        type: "varchar(10)",
        tableName: "Bio",
        label: "Middle Name",
      },
      {
        columnName: "suffix",
        type: "bit",
        tableName: "Bio",
        label: "Suffix",
        isID: false,
      },
      {
        columnName: "pinNo",
        type: "varchar(30)",
        tableName: "Bio",
        label: "Old CRH#",
      },
      {
        columnName: "questionedIdentity",
        type: "checkbox",
        tableName: "Bio",
        label: "Questioned Identity",
      },
      {
        columnName: "enrolleeID",
        type: "varchar(50)",
        tableName: "Bio",
        label: "Enrollee ID",
        disabled: true,
      },
      {
        columnName: "race",
        type: "bit",
        tableName: "Bio",
        label: "Race",
        required: true,
        isID: false,
      },
      {
        columnName: "sex",
        type: "bit",
        tableName: "Bio",
        label: "Gender",
        required: true,
        isID: false,
      },
      {
        columnName: "height",
        type: "int",
        tableName: "Bio",
        label: "Height",
      },
      {
        columnName: "weight",
        type: "int",
        tableName: "Bio",
        label: "Weight",
      },
      {
        columnName: "hair",
        type: "bit",
        tableName: "Bio",
        label: "Hair Color",
        isID: false,
      },
      {
        columnName: "eyes",
        type: "bit",
        tableName: "Bio",
        label: "Eye Color",
        isID: false,
      },
      {
        columnName: "glasses",
        type: "bit",
        tableName: "Bio",
        label: "Glasses",
        isID: false,
      },
      {
        refListName: "hair_Length",
        columnName: "hairLength",
        type: "bit",
        tableName: "Bio",
        label: "Hair Length",
        isID: false,
      },
      {
        refListName: "facial_Hair",
        columnName: "facialHair",
        type: "bit",
        tableName: "Bio",
        label: "Facial Hair",
        isID: false,
      },
      {
        columnName: "complexion",
        type: "bit",
        tableName: "Bio",
        label: "Complexion",
        isID: false,
      },
      {
        columnName: "ethnicity",
        type: "bit",
        tableName: "Bio",
        label: "Ethnicity",
      },
      {
        columnName: "build",
        type: "bit",
        tableName: "Bio",
        label: "Build",
      },
      {
        columnName: "skin",
        type: "bit",
        tableName: "Bio",
        label: "Skin",
      },
      {
        columnName: "teeth",
        type: "bit",
        tableName: "Bio",
        label: "Teeth",
      },
      {
        refListName: "Hair_Style",
        columnName: "hairStyle",
        type: "bit",
        tableName: "Bio",
        label: "Hair Style",
      },
      {
        columnName: "daytimePhone",
        type: "varchar(20)",
        tableName: "Bio",
        label: "Work Phone",
      },
      {
        columnName: "phone",
        type: "varchar(20)",
        tableName: "Bio",
        label: "Home Phone",
      },
      {
        columnName: "cellPhone",
        type: "varchar(20)",
        tableName: "Bio",
        label: "Cell Phone",
      },
      {
        columnName: "dlNumber",
        type: "varchar(12)",
        tableName: "Bio",
        label: "Drivers License",
      },
      {
        refListName: "USState",
        columnName: "dlState",
        type: "bit",
        tableName: "Bio",
        label: "DL state",
        isID: false,
      },
      {
        columnName: "dlExpiration",
        type: "date",
        tableName: "Bio",
        label: "Expires",
      },
      {
        columnName: "ssn",
        type: "varchar(18)",
        tableName: "Bio",
        label: "SSN",
      },
      {
        columnName: "dob",
        type: "date",
        tableName: "Bio",
        label: "DOB",
        required: true,
      },
      {
        columnName: "ageAtEntry",
        type: "int",
        tableName: "Bio",
        label: "Age",
        disabled: true,
      },
    ],
  },
  arrest_book_info: {
    [PERSON.INMATE]: [
      {
        refListName: "Controlling_Agency",
        columnName: "agency",
        type: "bit",
        tableName: "ArrestReports",
        label: "Arresting ORI",
        isID: false,
        longStringRequired: true,
      },
      {
        refListName: "",
        columnName: "bookingOfficer",
        type: "varchar(20)",
        tableName: "ArrestReports",
        label: "Arresting Officer",
        isID: false,
      },
      {
        refListName: "",
        columnName: "officer",
        type: "bit",
        tableName: "ArrestReports",
        label: "Booking Officer",
        isID: false,
        isStaff: true,
      },
      {
        refListName: "",
        columnName: "incarcerationDateTime",
        type: "datetime",
        tableName: "ArrestReports",
        label: "Incarceration Date & Time",
        isID: false,
      },
      {
        refListName: "Inmate_Type",
        columnName: "inmateTypeId",
        type: "bit",
        tableName: "ArrestReports",
        label: "Inmate Type",
        isID: true,
      },
      {
        refListName: "",
        columnName: "controllingAgencyCaseNumber",
        type: "varchar(20)",
        tableName: "ArrestReports",
        label: "Controlling Agency Number",
        isID: false,
        disabled: true,
      },
      {
        refListName: "Controlling_Agency",
        columnName: "controllingAgency_OriId",
        type: "bit",
        tableName: "ArrestReports",
        label: "Controlling Agency",
        isID: false,
        longStringRequired: true,
      },
    ],
    [PERSON.BOOKINGS]: [
      {
        refListName: "Controlling_Agency",
        columnName: "agency",
        type: "bit",
        tableName: "ArrestReports",
        label: "Arresting ORI",
        isID: false,
        longStringRequired: true,
      },
      {
        refListName: "",
        columnName: "officer",
        type: "varchar(20)",
        tableName: "ArrestReports",
        label: "Arresting Officer",
        isID: false,
      },
      {
        refListName: "",
        columnName: "arrestLocation",
        type: "varchar(20)",
        tableName: "ArrestReports",
        label: "Arresting Location",
        isID: false,
      },
      {
        refListName: "Arrest_City",
        columnName: "arrestCity",
        type: "bit",
        tableName: "ArrestReports",
        label: "Arrest City",
        isID: false,
      },
      {
        refListName: "",
        columnName: "arrestDateTime",
        type: "datetime",
        tableName: "ArrestReports",
        label: "Arrest Date & Time",
        isID: false,
      },
      {
        refListName: "Towing_Company",
        columnName: "towingCompany",
        type: "bit",
        tableName: "ArrestReports",
        label: "Towing Company",
        isID: false,
      },
      {
        refListName: "",
        columnName: "intakeWarCheckDate",
        type: "date",
        tableName: "ArrestReports",
        label: "Intake War Check Date",
        isID: false,
      },
    ],
  },
};

export const CONFIG_STAGES = {
  INITIAL: 0,
  SELECTED: 1,
  DEPLOY: 2,
  STAGE: 3,
  SAVE_CONFIG: 4,
  SEARCHING: 5,
  MAP_VIEW: 6,
  TABLE_VIEW: 7,
};
